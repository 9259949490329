import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image"
import { useSprings, animated, interpolate, config } from "react-spring"
import { useDrag } from "react-use-gesture"
import useMeasure from "react-use-measure"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"

import { Heading, Copy } from "./typography"
import useWindowSize from "./use-window-size"

// function clamp(number, lower, upper) {
//   if (number > upper) {
//     return upper
//   } else if (number < lower) {
//     return lower
//   } else {
//     return number
//   }
// }

const Gallery = styled(animated.div)`
  margin-top: 30px;
  margin-bottom: 30px;
  width: auto;
  overflow-x: hidden;
  overflow-block: hidden;
  overflow: hidden;
  display: grid;
  row-gap: 15px;
  /* grid-template-columns: 1fr; */
  /* grid-template-rows: auto max-content auto; */

  opacity: ${(props) => (props.hide === "true" ? "0" : "1")};
  transform: ${(props) =>
    props.hide === "true" ? "translateY(50px)" : "translateY(0px)"};

  transition-property: opacity, transform;
  transition-duration: 500ms;
  transition-timing-function: ease-in-out;
  transition-delay: 200ms;

  & > h1 {
    margin-left: 25px;
    margin-bottom: 15px;
    grid-row: 1;
  }

  & > * {
    /* first div with most of the effects */
    grid-column: 1;
    grid-row: 2;
    overflow-block: hidden;
    object-fit: cover;
    border-radius: inherit;
    & > * {
      /* second div with the scale effect */
      height: 100%;
      object-fit: cover;
      border-radius: inherit;
      & > * {
        /* Gatsby-image div */
        height: 100%;
        object-fit: cover;
        border-radius: inherit;
      }
    }
  }

  /* Next and Back Buttons */
  & > aside {
    grid-row: 3;
    z-index: 2;
    width: calc(100% - 30px);
    margin: 10px 15px 0;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  @media screen and (min-width: 992px) {
    
    margin: 90px 30px;
    column-gap: 60px;

    /* & > :first-child {
      align-self: center;
      justify-self: center;
      grid-column: ${(props) => `${props.right ? "2" : "1"}`};
      width: stretch;
      height: auto;
      max-height: 80vh;
    } */
  }

  @media screen and (min-width: 1200px) {
    width: 1110px;
    margin: 120px auto;
  }
`

const BackAndForwardButtons = styled.div`
  height: 44px;

  & > button {
    width: 44px;
    height: 44px;
    transform: none;
    transition: transform 300ms ease-in-out;

    &:hover,
    &:focus {
      transform: scale(0.95);
    }

    &:first-of-type {
      justify-self: start;
      transform: rotate(180deg);
    }

    &:nth-of-type(2) {
      justify-self: end;
    }

    & > svg > polyline {
      fill: none;
      stroke: hsl(0, 0%, 20%);
      stroke-miterlimit: 10;
      stroke-width: 1.25;
    }
  }
`
const ProgressIndicator = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-variant: tabular-nums;
  transform: translateY(-1px);

  & > p {
    margin: 0 10px;
    margin-bottom: 0;
    height: fit-content;
  }

  & > hr {
    height: 1px;
    width: 100px;
    background: hsl(0, 0%, 20%);
    border: none;
    transform: translateY(2px);
  }
`

const Paragraphs = styled.div`
  grid-row: 4;
  margin-left: 25px;
  margin-right: 25px;
  max-width: 40ch;

  @media screen and (min-width: 768px) {
    margin-left: 30px;
    margin-right: 30px;
  }
`

const GalleryComponent = ({
  title,
  copy,
  gallery,
  // contentOnRight
}) => {
  const [currentImage, setCurrentImage] = useState(0)
  const windowSize = useWindowSize()
  const [ref, { width }] = useMeasure()
  // const galleryWidth =
  //   windowSize.width >= 992
  //     ? "100%"
  //     : width !== "undefined"
  //     ? width
  //     : windowSize.width

  const galleryWidth = width ? width : windowSize.width

  const [windowHeight, setWindowHeight] = useState(0)

  if (windowHeight === 0) {
    setWindowHeight(windowSize.height)
  }

  const elementRef = useRef(0)

  const [hideOnScroll, setHideOnScroll] = useState(true)

  useScrollPosition(
    () =>
      setHideOnScroll(
        elementRef.current.getBoundingClientRect().top > windowSize.height
      ),
    [hideOnScroll]
  )

  function getImagePositions(currentImage, gallery) {
    const currentImageBackOne =
      currentImage - 1 < 0 ? gallery.length - 1 : currentImage - 1
    const currentImageForwardOne =
      currentImage + 1 > gallery.length - 1
        ? (currentImage + 1) % gallery.length
        : currentImage + 1
    return {
      backOne: currentImageBackOne,
      currentImage: currentImage,
      forwardOne: currentImageForwardOne,
    }
  }

  const [springs, set] = useSprings(gallery.length, (index) => {
    const imagePositions = getImagePositions(currentImage, gallery)
    return {
      x: 0, // index * window.innerWidth,
      borderRadius: 0,
      opacity: index === currentImage ? 1 : 0,
      scale: 1,
      z: 0,
      display: `${
        index === imagePositions.backOne ||
        index === imagePositions.currentImage ||
        index === imagePositions.forwardOne
          ? "unset"
          : "none"
      }`,
      containerHeight: Math.min(
        (gallery[currentImage].file.details.image.height /
          gallery[currentImage].file.details.image.width) *
          galleryWidth,
        windowSize.height * 0.8
      ),
      config: config.stiff,
    }
  })

  const bind = useDrag(
    ({ down, movement: [mx], direction: [xDir], distance, cancel }) => {
      const imagePositions = getImagePositions(currentImage, gallery)

      if (down && distance > galleryWidth / 2) {
        cancel(
          setCurrentImage(
            xDir > 0 ? imagePositions.backOne : imagePositions.forwardOne
          )
        )
      }
      set((i) => {
        if (
          i !== imagePositions.backOne &&
          i !== currentImage &&
          i !== imagePositions.forwardOne
        )
          return { display: "none" }

        const opacity =
          i === currentImage
            ? 1
            : i === imagePositions.backOne
            ? mx >= 0
              ? 1
              : 0
            : i === imagePositions.forwardOne
            ? mx <= 0
              ? 1
              : 0
            : 0

        // This is the horizontal position of the images
        const x =
          i === imagePositions.backOne
            ? mx >= 0
              ? 0
              : galleryWidth * -1
            : i === imagePositions.forwardOne
            ? mx <= 0
              ? 0
              : galleryWidth
            : down
            ? mx
            : 0
        const scale =
          i !== currentImage
            ? 1
            : down
            ? 1.025 // - distance / window.innerWidth / 2
            : 1
        const borderRadius = i === currentImage && down ? 2 : 0
        const z =
          i === currentImage
            ? 30
            : i === imagePositions.backOne
            ? mx >= 0
              ? 2
              : 1
            : i === imagePositions.forwardOne
            ? mx <= 0
              ? 2
              : 1
            : 0

        const containerHeight = Math.min(
          (gallery[currentImage].file.details.image.height /
            gallery[currentImage].file.details.image.width) *
            galleryWidth,
          windowSize.height * 0.8
        )
        return {
          x,
          scale,
          opacity,
          borderRadius,
          display: "unset",
          z,
          containerHeight,
        }
      })
    }
  )

  return (
    <Gallery
      ref={(ref, elementRef)}
      hide={hideOnScroll.toString()}
      style={{
        gridTemplateRows: springs[currentImage].containerHeight.interpolate(
          (ch) => `auto ${Math.floor(ch)}px auto`
        ),
        // gridTemplateColumns:
        //   windowSize.width < 992
        //     ? "auto"
        //     : contentOnRight
        //     ? "45ch 1fr"
        //     : "1fr 45ch",
      }}
    >
      {title ? <Heading>{title}</Heading> : null}
      {springs.map(({ x, display, opacity, scale, borderRadius, z }, index) => (
        <animated.div
          {...bind()}
          key={index}
          style={{
            display,
            borderRadius: borderRadius.interpolate((br) => `${br}%`),
            transform: interpolate(
              [x, z],
              (x, z) => `perspective(1000px) translate3d(${x}px,0,${z}px)`
            ),
            opacity: opacity.interpolate((o) => `${o}`),
            zIndex: currentImage === index ? "1" : null,
          }}
        >
          <animated.div
            style={{
              transform: scale.interpolate((s) => `scale(${s})`),
            }}
          >
            {gallery[index].fluid ? (
              <Img
                key={index}
                fluid={gallery[index].fluid}
                alt={gallery[index].description}
              />
            ) : gallery[index].file.url ? (
              <img
                key={index}
                src={gallery[index].file.url}
                alt={gallery[index].description}
              />
            ) : null}
          </animated.div>
        </animated.div>
      ))}
      <aside>
        <BackAndForwardButtons>
          <button
            onClick={() => {
              const updatedValue =
                currentImage - 1 < 0 ? gallery.length - 1 : currentImage - 1
              // currentImage < gallery.length - 1 ? currentImage + 1 : 0

              setCurrentImage(
                currentImage < 1 ? gallery.length - 1 : currentImage - 1
              )

              const imagePositions = getImagePositions(updatedValue, gallery)

              set((index) => ({
                x: 0,
                scale: 1,
                opacity: index === imagePositions.currentImage ? 1 : 0,
                borderRadius: 0,
                display:
                  index === imagePositions.backOne ||
                  index === imagePositions.currentImage ||
                  index === imagePositions.forwardOne
                    ? "unset"
                    : "none",
                z:
                  index === imagePositions.currentImage
                    ? 30
                    : index === imagePositions.backOne ||
                      index === imagePositions.forwardOne
                    ? 1
                    : 0,
                containerHeight: Math.min(
                  (gallery[imagePositions.currentImage].file.details.image
                    .height /
                    gallery[imagePositions.currentImage].file.details.image
                      .width) *
                    galleryWidth,
                  windowSize.height * 0.8
                ),
              }))
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 44 44"
              width="44"
              height="44"
            >
              <polyline points="20,16 26,22 20,28 " />
            </svg>
          </button>
          <button
            onClick={() => {
              const updatedValue =
                currentImage + 1 >= gallery.length
                  ? (currentImage + 1) % gallery.length
                  : currentImage + 1
              setCurrentImage(updatedValue)

              const imagePositions = getImagePositions(updatedValue, gallery)

              set((index) => ({
                x: 0,
                scale: 1,
                opacity: index === imagePositions.currentImage ? 1 : 0,
                borderRadius: 0,
                display:
                  index === imagePositions.backOne ||
                  index === imagePositions.currentImage ||
                  index === imagePositions.forwardOne
                    ? "unset"
                    : "none",
                z:
                  index === imagePositions.currentImage
                    ? 30
                    : index === imagePositions.backOne ||
                      index === imagePositions.forwardOne
                    ? 1
                    : 0,
                containerHeight: Math.min(
                  (gallery[imagePositions.currentImage].file.details.image
                    .height /
                    gallery[imagePositions.currentImage].file.details.image
                      .width) *
                    galleryWidth,
                  windowSize.height * 0.8
                ),
              }))
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 44 44"
              width="44"
              height="44"
            >
              <polyline points="20,16 26,22 20,28 " />
            </svg>
          </button>
        </BackAndForwardButtons>
        <ProgressIndicator>
          <Copy>{`${currentImage + 1}`}</Copy>
          <hr />
          <Copy>{`${gallery.length}`}</Copy>
        </ProgressIndicator>
      </aside>
      {copy ? (
        <Paragraphs>
          {copy.split("/n").map((paragraph, index) => (
            <Copy key={index}>{paragraph}</Copy>
          ))}
        </Paragraphs>
      ) : null}
    </Gallery>
  )
}

export default GalleryComponent

GalleryComponent.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.string,
  contentOnRight: PropTypes.bool,
  gallery: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      file: PropTypes.shape({
        url: PropTypes.string,
        details: PropTypes.shape({
          image: PropTypes.shape({
            height: PropTypes.number,
            width: PropTypes.number,
          }),
        }),
      }),
      fluid: PropTypes.object,
    })
  ).isRequired,
}
