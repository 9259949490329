import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import useWindowSize from "./use-window-size"

import { MenuSubheading } from "./typography"

const QuoteSection = styled.section`
  width: calc(100% - 100px);
  padding: 0 20px;
  border: 1px solid hsl(0, 0%, 20%);
  margin: 120px 30px 60px;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  opacity: ${(props) => (props.hideOnScroll ? "0" : "1")};
  transform: ${(props) =>
    props.hideOnScroll ? "translateY(50px)" : "translateY(0px)"};

  transition-property: opacity, transform;
  transition-duration: 500ms;
  transition-timing-function: ease-in-out;
  transition-delay: 200ms;

  & > svg {
    display: inline-block;
    padding: 0 10px;
    background: white;

    & > path {
      opacity: 0.25;
    }
  }

  & > :first-child {
    transform: translateY(-50%);
  }

  & > :nth-child(2) {
    margin-bottom: 0px;
  }

  & > :nth-child(3) {
    transform: translateY(50%);
  }

  @media screen and (min-width: 768px) {
    width: 50ch;
    justify-self: center;
    margin-top: 0px;
  }

  @media screen and (min-width: 992px) {
    margin-top: 90px;
    margin-bottom: 150px;
  }

  @media screen and (min-width: 1200px) {
    width: 70ch;
    margin: 30px auto 150px;
  }
`

const QuoteComponent = ({ quote }) => {
  const windowSize = useWindowSize()

  const elementRef = useRef(0)
  const [hideOnScroll, setHideOnScroll] = useState(true)
  useScrollPosition(
    () =>
      setHideOnScroll(
        elementRef.current.getBoundingClientRect().top > windowSize.height
      ),
    [hideOnScroll]
  )
  return (
    <QuoteSection ref={elementRef} hideOnScroll={hideOnScroll}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 44 44"
        width="44"
        height="44"
      >
        <path
          d="M13.16,21.61c1.98,0,3.57,0.6,4.77,1.81c1.2,1.21,1.81,2.8,1.81,4.77c0,1.81-0.67,3.38-2,4.71
		c-1.33,1.33-2.99,2-4.97,2c-2.06,0-3.85-0.84-5.35-2.52c-1.51-1.68-2.26-3.76-2.26-6.26c0-4.73,1.25-8.58,3.74-11.55
		c2.5-2.95,5.34-4.78,8.52-5.47l0.26,0.9c-2.84,0.61-5.4,2.26-7.68,4.97s-3.42,5.87-3.42,9.48c0,0.43,0.04,0.86,0.13,1.29
		C7.92,22.99,10.07,21.61,13.16,21.61z M32.26,21.61c1.98,0,3.57,0.6,4.77,1.81c1.2,1.21,1.81,2.8,1.81,4.77
		c0,1.81-0.67,3.38-2,4.71c-1.33,1.33-2.99,2-4.97,2c-2.06,0-3.85-0.84-5.35-2.52c-1.51-1.68-2.26-3.76-2.26-6.26
		c0-4.73,1.25-8.58,3.74-11.55s5.33-4.79,8.51-5.48L36.77,10c-2.84,0.6-5.4,2.26-7.68,4.97s-3.42,5.87-3.42,9.48
		c0,0.43,0.04,0.86,0.13,1.29C27.01,22.99,29.16,21.61,32.26,21.61z"
        />
      </svg>
      <MenuSubheading>{quote}</MenuSubheading>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 44 44"
        width="44"
        height="44"
      >
        <path
          d="M11.74,22.45c-1.89,0-3.46-0.65-4.71-1.94c-1.25-1.29-1.87-2.88-1.87-4.77c0-1.81,0.67-3.37,2-4.71
		c1.33-1.33,2.99-2,4.97-2c2.06,0,3.85,0.84,5.35,2.52s2.26,3.76,2.26,6.26c0,4.82-1.25,8.69-3.74,11.61
		c-2.5,2.92-5.33,4.77-8.51,5.55l-0.26-1.03c2.84-0.6,5.4-2.24,7.68-4.9c2.28-2.67,3.42-5.85,3.42-9.55c0-0.34-0.04-0.77-0.13-1.29
		C16.9,21.03,14.75,22.45,11.74,22.45z M30.84,22.45c-1.89,0-3.46-0.65-4.71-1.94c-1.25-1.29-1.87-2.88-1.87-4.77
		c0-1.81,0.67-3.37,2-4.71c1.33-1.33,2.99-2,4.97-2c2.06,0,3.85,0.84,5.35,2.52s2.26,3.76,2.26,6.26c0,4.82-1.25,8.69-3.74,11.61
		c-2.5,2.92-5.33,4.77-8.51,5.55l-0.26-1.03c2.84-0.6,5.4-2.24,7.68-4.9c2.28-2.67,3.42-5.85,3.42-9.55c0-0.34-0.04-0.77-0.13-1.29
		C36,21.03,33.85,22.45,30.84,22.45z"
        />
      </svg>
    </QuoteSection>
  )
}

export default QuoteComponent

QuoteComponent.propTypes = {
  quote: PropTypes.string.isRequired,
}
